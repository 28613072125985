import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import BenefitItem from "./BenefitItem";
import "./BenefitsList.scss";

interface BenefitsListProps {
  benefits: Benefit[];
}

interface BenefitsListBaseProps extends BenefitsListProps {
  images: SvgImage[];
}

export const BenefitsListBase: React.FC<BenefitsListBaseProps> = ({
  benefits,
  images,
}) => (
  <div className="g-centered font-size-3 benefits">
    <h2>Benefits</h2>
    <ul className="two-col-text benefits__list">
      {benefits.map((benefit) => (
        <BenefitItem benefit={benefit} images={images} key={benefit.title} />
      ))}
    </ul>
  </div>
);

const BenefitsList: React.FC<BenefitsListProps> = (props) => (
  <StaticQuery
    query={graphql`
      query BenefitsListQuery {
        allFile(filter: { relativeDirectory: { eq: "careers" } }) {
          nodes {
            publicURL
            name
          }
        }
      }
    `}
    render={(data) => (
      <BenefitsListBase images={data.allFile.nodes} {...props} />
    )}
  />
);

export default BenefitsList;
