import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import EmailLink from "./EmailLink";

type OpenPosition = {
  id: string;
  title: string;
  location: string;
  url: string;
  type: string;
};

interface OpenPositionsProps {
  openPositions: OpenPosition[];
  totalCount: number;
}

export const noPositonsTestId = "no-open-positions";

export const OpenPositionsBase: React.FC<OpenPositionsProps> = ({
  openPositions,
  totalCount,
}) => {
  if (totalCount < 1) {
    return (
      <>
        <h2>Open Positions</h2>
        <div className="careers__empty" data-testid={noPositonsTestId}>
          We don&apos;t currently have any positions open. If you&apos;re
          interested in working with us, send us an email at{" "}
          <EmailLink
            address="contact@ideas42ventures.com"
            displayEmail
            size="med"
          />
          .
        </div>
      </>
    );
  }
  return (
    <>
      <h2>Open Positions</h2>
      <ul className="careers__job-list">
        {openPositions.map(({ title, type, location, id, url }) => (
          <li className="careers__job" key={id}>
            <a
              className="card careers__card"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="careers__title">{title}</span>
              <p className="careers__type">{type}</p>
              <p className="careers__location">{location}</p>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

const OpenPositions: React.FC = () => (
  <StaticQuery
    query={graphql`
      {
        allOpenPositionsJson {
          totalCount
          nodes {
            url
            type
            title
            location
            id
          }
        }
      }
    `}
    render={({ allOpenPositionsJson }) => (
      <OpenPositionsBase
        openPositions={allOpenPositionsJson.nodes}
        totalCount={allOpenPositionsJson.totalCount}
      />
    )}
  />
);

export default OpenPositions;
