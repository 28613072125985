import * as React from "react";

type BenefitItemProps = {
  benefit: Benefit;
  images: SvgImage[];
};

const BenefitItem: React.FC<BenefitItemProps> = ({ benefit, images }) => {
  const { text, title, image } = benefit;
  const CareerImage: string | undefined = images.find(
    (img) => img.name === image
  )?.publicURL;

  return (
    <li className="two-col-text__contain benefits__card">
      {CareerImage && (
        <img src={CareerImage} alt="" className="benefits__icon" />
      )}
      <h3 className="benefits__subheader">{title}</h3>
      <div className="benefits__text">{text}</div>
    </li>
  );
};

export default BenefitItem;
