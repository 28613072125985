import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Masthead from "../components/Masthead";
import BenefitsList from "../components/BenefitsList";
import { benefits } from "../data/careers";
import OpenPositions from "../components/OpenPositions";

import "../styles/careers.scss";

const CareersPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const pageTitle = "ideas42 Venture Studio - Careers";

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <Masthead>
        <Hero
          tag="Careers"
          title="Join our team"
          titleClass="g-max-3"
          body="Are you looking to have an impact on the lives of millions, while helping talented, diverse entrepreneurs design their businesses from day one? Do you dream of building products that have a positive impact on a global scale? At the ideas42 Venture Studio, we build impact businesses that solve massive social challenges, and we are looking for talented individuals to join our team."
          bodyClass="hero__body g-max-3"
        />
      </Masthead>

      <section className="standard-restricted-width g-centered font-size-3">
        <OpenPositions />
      </section>
      <section className="standard-restricted-width">
        <BenefitsList benefits={benefits} />
      </section>
    </Layout>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query CareersPage {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <CareersPage data={data} {...props} />}
  />
);
