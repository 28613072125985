import React from "react";

export const benefits = [
  {
    title: "Compensation",
    image: "compensation",
    text: `Competitive and transparent pay structure across our team to promote an equal pay workplace.`,
  },
  {
    title: "Holidays and Vacation",
    image: "pto",
    text: `Unlimited PTO. All US Federal Holidays + Juneteenth + the week between Christmas and New Years.`,
  },
  {
    title: "Ownership in companies",
    image: "ownership",
    text: `Participation in a profit sharing plan among all companies built in the studio.`,
  },
  {
    title: "401k",
    image: "retirement",
    text: `401k plan with a 3% employer contribution and no employee contribution requirement`,
  },
  {
    title: "Healthcare",
    image: "health",
    text: (
      <>
        <p>
          100% health, dental and vision costs covered with multiple plan
          options available
        </p>
        <ul>
          <li>
            HSA with employer contribution or FSA (depending on plan selected)
          </li>
          <li>One Medical membership</li>
          <li>24/7 Health Advocate support</li>
        </ul>
      </>
    ),
  },
  {
    title: "Parental leave",
    image: "parental",
    text: `16 weeks of parental leave and flexible work schedule so you can be present at times that matter.`,
  },
  {
    title: "Home office setup",
    image: "office",
    text: (
      <ul>
        <li>Laptop of your choosing</li>
        <li>
          Funding to properly configure your home office, or cover the cost of a
          desk at a coworking space if you prefer that.
        </li>
        <li>Monthly stipend for phone and internet</li>
      </ul>
    ),
  },
];
