import * as React from "react";
import classNames from "classnames";
import "./Masthead.scss";

type MastheadProps = {
  children: React.ReactNode;
  className?: string;
};

const Masthead: React.FC<MastheadProps> = ({ children, className }) => (
  <div className={classNames("masthead", className)}>{children}</div>
);

export default Masthead;
