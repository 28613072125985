import React from "react";
import classNames from "classnames";

import "./EmailLink.scss";

export interface EmailLinkProps {
  address: string;
  displayEmail?: boolean;
  size?: "small" | "med"; // add more sizes as necessary
}

// Reverse and encode/decode with base64 to dodge spammers
const EmailLink: React.FC<EmailLinkProps> = ({
  address,
  displayEmail = false,
  size = "small",
}) => {
  const reverse = (str) =>
    str === "" ? "" : reverse(str.substr(1)) + str.charAt(0);
  const encodedAddress = btoa(`mailto:${address}`);

  const onClick = () => {
    window.location.href = atob(encodedAddress);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames("btn--link", {
        reverse: displayEmail,
        "btn--small": size === "small",
        "btn--med": size === "med",
      })}
    >
      {displayEmail ? reverse(address) : "Email"}
    </button>
  );
};

export default EmailLink;
